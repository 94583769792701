import React, { useEffect, useState, useRef } from "react"
import { css } from "@emotion/core"
import { connect, useDispatch } from "react-redux"
import { navigate } from "gatsby"

import {
  LayoutQuoting,
  Title,
  SEO,
  SectionCenter,
  SecondaryButton,
  CoveragesDeductibles,
  PreExistingConditionToggle,
  PlanCard,
  Spinner,
  GoBack,
  PlanComparison,
} from "../components"
import {
  getCurrentPath,
  setAllData,
  quoteActionsToDispatch,
  clearQuotes,
  clearComparisonInsurer,
} from "../redux/actions"
import useInsurersLogos from "../hooks/use-insurers-logos"
import { mq } from "../utils/media-query"
import {
  getCoverages,
  getDeductibles,
  validTravellers,
  coverageDeductibleCombo,
  checkInsuerAvailability,
  setPlanCardData,
  setTravelPlanPayload,
  setTravellersPayload,
  setPayloadForQuote,
  ifAllQuotesLoaded,
  isCompareInsurers,
} from "../utils/helpers"
import { preExistingCoverage } from "../utils/quotesOptions"

const GetQuotePolicies = ({
  location,
  getCurrentPath,
  conditions,
  travellers,
  quotes,
  coverage,
  deductible,
  travelPlan,
  allCoveragesDeductibles,
  compareInsurer,
}) => {
  const dispatch = useDispatch()
  const [coverages, setCoverages] = useState([])
  const [deductibles, setDeductibles] = useState([])
  const [isValidTravellers, setIsValidTravellers] = useState(true)
  const [showCompare, setShowCompare] = useState(false)
  const conditionsLoaded = Object.keys(conditions).length > 0
  const insurerAvailability = useRef({})
  const logos = useInsurersLogos()
  const [preExisting, setPreExisting] = useState(true)
  const coverageItems = ["Medical emergency", "Pre-existing Conditions"]

  const onSelectPreExisting = e => {
    dispatch(clearQuotes())
    setPreExisting(e.target.value === "include")
  }

  const compareInsurers = () => {
    if (!isCompareInsurers(compareInsurer)) return
    setShowCompare(true)
  }

  useEffect(() => {
    getCurrentPath(location.pathname)
    setIsValidTravellers(validTravellers(travellers))
    return () => {
      dispatch(clearComparisonInsurer())
    }
  }, [])

  useEffect(() => {
    if (Object.keys(conditions).length <= 0) return
    const all = coverageDeductibleCombo(conditions)
    dispatch(setAllData(all))
    setCoverages(getCoverages(all))
    setDeductibles(getDeductibles(all))
  }, [conditions, dispatch])

  useEffect(() => {
    if (!isValidTravellers) return
    const makeQuoteAPICall = insurer => {
      const travelPlanPayload = setTravelPlanPayload(travelPlan)
      const travellersPayload = setTravellersPayload(travellers)
      const quotePayload = setPayloadForQuote(
        travelPlanPayload,
        travellersPayload,
        coverage,
        deductible,
        preExisting
          ? preExistingCoverage[insurer]
            ? [preExistingCoverage[insurer]]
            : []
          : []
      )
      dispatch(quoteActionsToDispatch[insurer](quotePayload))
    }

    insurerAvailability.current = checkInsuerAvailability(
      coverage,
      deductible,
      allCoveragesDeductibles,
      preExisting
    )

    Object.keys(insurerAvailability.current).forEach(insurer => {
      if (insurerAvailability.current[insurer]) {
        makeQuoteAPICall(insurer)
      }
    })
  }, [coverage, deductible, preExisting, allCoveragesDeductibles])

  const onGoBack = () => {
    navigate("/get-quote-travellers")
  }

  return (
    <LayoutQuoting>
      <SEO title="Get my quote - policies" />
      <section
        css={css`
          padding: 5rem 0;
        `}
      >
        {isValidTravellers ? (
          showCompare ? (
            <>
              <Title
                title="compare benefits"
                fontColor="var(--clr-primary-300)"
              />
              <SectionCenter>
                <PlanComparison
                  onGoBack={() => {
                    dispatch(clearComparisonInsurer())
                    setShowCompare(false)
                  }}
                />
              </SectionCenter>
            </>
          ) : (
            <>
              <Title
                title="here are your plans"
                fontColor="var(--clr-primary-300)"
              />
              {!conditionsLoaded ? (
                <Spinner />
              ) : (
                <SectionCenter>
                  <div
                    css={css`
                      width: 90%;
                      display: grid;
                      grid-template-columns: 1fr;
                      row-gap: 1rem;
                      margin: 0 auto 4rem auto;

                      ${mq[0]} {
                        justify-content: center;
                        padding-left: 4rem;
                      }
                    `}
                  >
                    <CoveragesDeductibles type="coverage" data={coverages} />
                    <CoveragesDeductibles
                      type="deductible"
                      data={deductibles}
                    />
                    <PreExistingConditionToggle
                      preExisting={preExisting}
                      onChange={onSelectPreExisting}
                    />
                    {ifAllQuotesLoaded(quotes, insurerAvailability.current) ? (
                      <>
                        {Object.keys(insurerAvailability.current)
                          .sort((a, b) => {
                            if (
                              Object.keys(quotes[a]).length > 0 &&
                              Object.keys(quotes[b]).length > 0
                            ) {
                              return (
                                quotes[a].price.premium -
                                quotes[b].price.premium
                              )
                            }
                            if (insurerAvailability.current[a]) return -1
                            return 0
                          })
                          .map(insurer => {
                            if (insurerAvailability.current[insurer]) {
                              const plan = setPlanCardData(
                                insurer,
                                logos[insurer].fluid,
                                quotes[insurer].price.premium,
                                insurer === "gms" || insurer === "tugo"
                                  ? coverageItems
                                  : preExisting
                                  ? coverageItems
                                  : coverageItems.filter(
                                      item =>
                                        !item
                                          .toLowerCase()
                                          .includes("pre-existing")
                                    ),
                                travelPlan.endDate.diff(
                                  travelPlan.startDate,
                                  "days"
                                ) + 1
                              )
                              return <PlanCard plan={plan} key={insurer} />
                            }
                            const notAvailPlan = setPlanCardData(
                              insurer,
                              logos[insurer].fluid,
                              null,
                              [],
                              travelPlan.endDate.diff(
                                travelPlan.startDate,
                                "days"
                              ) + 1
                            )
                            if (
                              !preExisting &&
                              !insurerAvailability.current[insurer]
                            ) {
                              return (
                                <PlanCard
                                  plan={notAvailPlan}
                                  key={insurer}
                                  notAvailable
                                  noPreExisting
                                />
                              )
                            }
                            return (
                              <PlanCard
                                plan={notAvailPlan}
                                key={insurer}
                                notAvailable
                              />
                            )
                          })}
                      </>
                    ) : (
                      <Spinner />
                    )}
                  </div>
                  <div
                    css={css`
                      display: flex;
                      justify-content: center;
                    `}
                  >
                    <SecondaryButton
                      buttonColor="var(--clr-primary-300)"
                      textSize="0.875rem"
                      margin="0 4rem 0 0"
                      disabled={!isCompareInsurers(compareInsurer)}
                      disabledBorder="2px"
                      onClick={compareInsurers}
                    >
                      compare benefits
                    </SecondaryButton>
                    <SecondaryButton
                      buttonColor="var(--clr-neutral-400)"
                      textSize="0.875rem"
                      margin="none"
                      onClick={onGoBack}
                    >
                      Go back
                    </SecondaryButton>
                  </div>
                </SectionCenter>
              )}
            </>
          )
        ) : (
          <GoBack
            backToLink="/get-quote-travellers"
            notFilledOut="travellers"
          />
        )}
      </section>
    </LayoutQuoting>
  )
}

const mapStateToProps = ({
  conditions,
  travellers,
  quotes,
  coverage,
  deductible,
  travelPlan,
  allCoveragesDeductibles,
  apiCallStatus,
  compareInsurer,
}) => ({
  conditions,
  travellers,
  quotes,
  coverage,
  deductible,
  travelPlan,
  allCoveragesDeductibles,
  apiCallStatus,
  compareInsurer,
})

export default connect(mapStateToProps, { getCurrentPath })(GetQuotePolicies)
